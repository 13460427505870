import { useEffect, useState } from "react";

const useLookups = (fetchCountries, fetchCities) => {
	const [lookups, setLookups] = useState({ countries: [], cities: [] });

	const loadCountries = async () => {
		try {
			const response = await fetchCountries();
			setLookups((prev) => ({ ...prev, countries: response.data }));
		} catch (error) {
			console.error("Error fetching countries:", error);
		}
	};

	const loadCities = async (countryId) => {
		try {
			const response = await fetchCities(countryId);
			setLookups((prev) => ({ ...prev, cities: response.data }));
		} catch (error) {
			console.error("Error fetching cities:", error);
		}
	};

	useEffect(() => {
		loadCountries();
		// eslint-disable-next-line
	}, []);

	return { lookups, loadCities };
};

export default useLookups;
