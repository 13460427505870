import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import { Modal } from "react-bootstrap";

export default function RejectModal({
	isOpen,
	setIsOpen,
	rejectMessage,
	setRejectMessage,
	handleReject,
}) {
	return (
		<Modal show={isOpen} onHide={() => setIsOpen(false)} id="reject-modal">
			<Modal.Header closeButton>
				<Modal.Title>Reject Request</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<TextAreaField
					label="Rejection Reason"
					value={rejectMessage}
					onChange={(e) => setRejectMessage(e.target.value)}
					placeholder="Please enter the reason for rejection"
					rows={5}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					className="btn btn-danger w-100"
					onClick={async () => {
						await handleReject();
						setIsOpen(false);
						setRejectMessage("");
					}}
				>
					Reject Request
				</button>
			</Modal.Footer>
		</Modal>
	);
}
