import { useEffect, useState } from "react";

const useListData = (fetchDataFunction, filters) => {
	const [list, setList] = useState([]);
	const [meta, setMeta] = useState(null);

	const fetchListData = async (overrides = {}) => {
		try {
			const response = await fetchDataFunction({ ...filters, ...overrides });
			setList(response.data.data);
			setMeta(response.data.meta);
		} catch (error) {
			console.error("Error fetching list data:", error);
		}
	};

	useEffect(() => {
		fetchListData();
		// eslint-disable-next-line
	}, [filters]);

	return { list, meta, fetchListData };
};

export default useListData;
