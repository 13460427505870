import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
	approveFlightRequest,
	getFlight,
	rejectFlightRequest,
} from "services/staticRequests";
import { REQUEST_STATUS } from "../../constants";
import ImageUpload from "../../shared/ImageUpload";
import RejectModal from "../../shared/RejectModal";
import { uploadImagesFromFormState } from "../../shared/utils";

const requiredFields = ["airlineNameEn", "airlineNameAr", "country", "logo"];

const initialFormState = {
	values: {
		airlineNameEn: "",
		airlineNameAr: "",
		country: "",
		logo: [],
	},
	errors: {},
};

export default function EditFlight() {
	const { id } = useParams();
	const [formState, setFormState] = useState(initialFormState);
	const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
	const [rejectMessage, setRejectMessage] = useState("");
	const { allCountries } = useGlobalState();

	const history = useHistory();
	const [status, setStatus] = useState("idle");

	const handleOnChange = ({ key, value, validation }) => {
		setFormState((prev) => ({
			...prev,
			values: {
				...prev.values,
				[key]: value,
			},
			errors: {
				...prev.errors,
				...validate({ name: key, value: value }, validation),
			},
		}));
	};

	const checkFormErrors = () => {
		let submitErrors = {};
		requiredFields.forEach((key) => {
			submitErrors = {
				...submitErrors,
				...validate(
					{ name: key, value: formState.values[key] },
					{ required: true }
				),
			};
		});

		setFormState({
			...formState,
			errors: submitErrors,
		});

		return submitErrors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const errors = checkFormErrors();

		if (!isFormValid(errors)) return;

		// Call API
		const [logoURL] = await uploadImagesFromFormState(formState.values.logo);

		const requestBody = {
			name: {
				en: formState.values.airlineNameEn,
				ar: formState.values.airlineNameAr,
			},
			country_id: formState.values.country.id,
			logo: logoURL,
		};

		const res = await approveFlightRequest(id, requestBody);

		if (res.status === 200) {
			store.addNotification({
				title: "Success!",
				message: "Hotel request has been approved successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				dismiss: {
					duration: 2000,
					onScreen: true,
				},
			});

			history.replace("/static-requests/flights");
		}
	};

	const handleReject = async () => {
		const res = await rejectFlightRequest(id, { reject_reason: rejectMessage });

		if (res.status === 200) {
			store.addNotification({
				title: "Success!",
				message: "Hotel request has been rejected successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				dismiss: {
					duration: 2000,
					onScreen: true,
				},
			});

			history.replace("/static-requests/flights");
		}
	};

	useEffect(() => {
		// Fetch Flight Details
		const loadFlightRequestData = async () => {
			const res = await getFlight(id);
			if (res.status === 200) {
				const { data } = res.data;

				setFormState({
					...formState,
					values: {
						airlineNameEn: data.names?.en,
						airlineNameAr: data.names?.ar,
						country: {
							...data.country,
							label: data.country.names?.en,
							value: data.country.id,
						},
						logo: [data.logo].filter(Boolean),
					},
				});

				setStatus(REQUEST_STATUS[res.data.data.status]);
			}
		};

		loadFlightRequestData();
		// eslint-disable-next-line
	}, [id]);

	return (
		<div className="static-requests">
			<h1>Details</h1>
			<hr />

			<form
				className="airline-edit-form"
				onSubmit={handleSubmit}
				id="airline-request"
			>
				<div className="row">
					<div className="col-6 p-0">
						<TextField
							label="Airline Name (EN)"
							placeholder="Enter name in English"
							name="airlineNameEn"
							id="airlineNameEn"
							value={formState.values.airlineNameEn}
							onChange={(e) =>
								handleOnChange({
									key: "airlineNameEn",
									value: e.target.value,
									validation: { required: true },
								})
							}
							color={formState.errors?.airlineNameEn?.required ? "danger" : ""}
							errors={formState.errors?.airlineNameEn}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-6 p-0">
						<TextField
							label="Airline Name (AR)"
							placeholder="Enter name in Arabic"
							name="airlineNameAr"
							id="airlineNameAr"
							value={formState.values.airlineNameAr}
							onChange={(e) =>
								handleOnChange({
									key: "airlineNameAr",
									value: e.target.value,
									validation: { required: true },
								})
							}
							color={formState.errors?.airlineNameAr?.required ? "danger" : ""}
							errors={formState.errors?.airlineNameAr}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-12"></div>

					<div className="col-6 p-0">
						<SelectField
							label="Country"
							placeholder="Select Option"
							name="country"
							id="country"
							value={formState.values.country}
							options={allCountries.map((country) => ({
								...country,
								value: country.id,
								label: country.names?.en,
							}))}
							onChange={(e) =>
								handleOnChange({
									key: "country",
									value: e,
									validation: { required: true },
								})
							}
							color={formState.errors?.country?.required ? "danger" : ""}
							errors={formState.errors?.country}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-12"></div>

					<div className="col-6 p-0">
						<ImageUpload
							label="Logo"
							setImages={(selectedImages) => {
								handleOnChange({
									key: "logo",
									value: selectedImages,
									validation: { required: true },
								});
							}}
							images={formState?.values?.logo}
							showError={formState.errors?.logo?.required}
							disabled={status !== "pending"}
						/>
					</div>
				</div>
			</form>

			<div className="bottom-actions d-flex justify-content-between mt-4 px-2">
				<Link to="/static-requests/flights">
					<button className="btn btn-primary">Close</button>
				</Link>

				{status === "pending" && (
					<div className="d-flex">
						<button
							className="btn btn-danger me-2"
							onClick={() => {
								setIsRejectModalOpen(true);
							}}
						>
							Reject
						</button>
						<button
							className="btn btn-primary"
							form="airline-request"
							type="submit"
						>
							Save & Add Approve
						</button>
					</div>
				)}
			</div>

			<RejectModal
				isOpen={isRejectModalOpen}
				setIsOpen={setIsRejectModalOpen}
				rejectMessage={rejectMessage}
				setRejectMessage={setRejectMessage}
				handleReject={handleReject}
			/>
		</div>
	);
}
