import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	Link,
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { fetchCities, fetchPackageCountries } from "services/lookups";
import {
	approveHotelRequest,
	getHotel,
	rejectHotelRequest,
} from "services/staticRequests";
import { REQUEST_STATUS } from "../../constants";
import ImageUpload from "../../shared/ImageUpload";
import { PhoneInput } from "../../shared/PhoneField";
import RejectModal from "../../shared/RejectModal";
import { uploadImagesFromFormState } from "../../shared/utils";

const DEFAULT_COUNTRY_ID = 966;
const RATINGS = [1, 2, 3, 4, 5];

const requiredFields = [
	"hotelNameEn",
	"hotelNameAr",
	"country",
	"city",
	"rating",
	"address",
	"latitude",
	"longitude",
	"description",
	"amenities",
	"mainImage",
	"images",
	"phoneNumber",
];

const initialFormState = {
	values: {
		hotelNameEn: "",
		hotelNameAr: "",
		country: "",
		city: "",
		rating: "",
		address: "",
		latitude: "",
		longitude: "",
		description: "",
		amenities: [],
		mainImage: [],
		images: [],
		phoneNumber: "",
		phoneNumberCountry: "",
		phoneNumber2: "",
		phoneNumber2Country: "",
		whatsappNumber: "",
		whatsappNumberCountry: "",
		selectedCountry: "",
		amenity: "",
	},
	errors: {},
};

export default function EditHotel() {
	const { id } = useParams();
	const [formState, setFormState] = useState(initialFormState);
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);

	const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
	const [rejectMessage, setRejectMessage] = useState("");

	const [status, setStatus] = useState("idle");

	const history = useHistory();

	console.log("formState", formState);

	const handleOnChange = ({ key, value, validation = {} }) => {
		setFormState((prev) => ({
			...prev,
			values: {
				...prev.values,
				[key]: value,
			},
			errors: {
				...prev.errors,
				...validate({ name: key, value: value }, validation),
			},
		}));
	};

	const checkFormErrors = () => {
		let submitErrors = {};
		requiredFields.forEach((key) => {
			submitErrors = {
				...submitErrors,
				...validate(
					{ name: key, value: formState.values[key] },
					{ required: true }
				),
			};
		});

		setFormState({
			...formState,
			errors: submitErrors,
		});

		return submitErrors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const errors = checkFormErrors();

		if (!isFormValid(errors)) return;

		const [mainImageURLs, imagesURLs] = await Promise.all([
			await uploadImagesFromFormState(formState.values.mainImage),
			await uploadImagesFromFormState(formState.values.images),
		]);

		// Call API
		const reqestBody = {
			hotel_name: {
				en: formState.values.hotelNameEn,
				ar: formState.values.hotelNameAr,
			},
			country_id: formState.values.country.value,
			city_id: formState.values.city.value,
			rating: formState.values.rating.value,
			amenities: formState.values.amenities,
			phone: formState.values.phoneNumber,
			phone_code: formState.values.phoneNumberCountry?.id.toString() || "",
			second_phone: formState.values.phoneNumber2 || "",
			second_phone_code:
				formState.values.phoneNumber2Country?.id.toString() || "",
			whatsapp: formState.values.whatsappNumber || "",
			whatsapp_code:
				formState.values.whatsappNumberCountry?.id.toString() || "",
			address: formState.values.address,
			latitude: formState.values.latitude,
			longitude: formState.values.longitude,
			description: formState.values.description,
			images: imagesURLs,
			main_image: mainImageURLs[0],
		};

		const res = await approveHotelRequest(id, reqestBody);

		if (res.status === 200) {
			store.addNotification({
				title: "Success!",
				message: "Hotel request has been approved successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				dismiss: {
					duration: 2000,
					onScreen: true,
				},
			});

			history.replace("/static-requests/hotels");
		}
	};

	const handleReject = async () => {
		const res = await rejectHotelRequest(id, { reject_reason: rejectMessage });

		if (res.status === 200) {
			store.addNotification({
				title: "Success!",
				message: "Hotel request has been rejected successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				dismiss: {
					duration: 2000,
					onScreen: true,
				},
			});

			history.replace("/static-requests/hotels");
		}
	};

	const loadCities = async (countryId) => {
		const res = await fetchCities(countryId);
		setCities(res.data);
	};

	useEffect(() => {
		if (formState.values.country) {
			loadCities(formState.values.country.id);
		}

		setFormState((prev) => {
			const defaultCountry = countries.find(
				(country) => country.id === DEFAULT_COUNTRY_ID
			);

			return {
				...prev,
				values: {
					...prev.values,
					phoneNumberCountry: defaultCountry,
					phoneNumber2Country: defaultCountry,
					whatsappNumberCountry: defaultCountry,
				},
			};
		});
	}, [countries, formState.values.country]);

	useEffect(() => {
		const loadHotelRequestData = async () => {
			const res = await getHotel(id);

			setFormState((prev) => ({
				...prev,
				values: {
					...prev.values,
					hotelNameEn: res.data.data.hotel_names?.en || "",
					hotelNameAr: res.data.data.hotel_names?.ar || "",
					country: {
						...res.data.data.country,
						label: res.data.data.country.names.en,
						value: res.data.data.country.id,
					},
					city: {
						...res.data.data.city,
						label: res.data.data.city.names.en,
						value: res.data.data.city.id,
					},
					rating: {
						value: res.data.data.rating,
						label: res.data.data.rating,
					},
					amenities: res.data.data.amenities || [],
					phoneNumber: res.data.data.phone,
					phoneNumberCountry: countries.find(
						(country) => +country.id === +res.data.data.id
					),
					phoneNumber2: res.data.data.second_phone || "",
					phoneNumber2Country: countries.find(
						(country) => +country.id === +res.data.data.second_id
					),
					whatsappNumber: res.data.data.second_phone || "",
					whatsappNumberCountry: countries.find(
						(country) => +country.id === +res.data.data.second_id
					),
					address: res.data.data.address,
					latitude: res.data.data.latitude,
					longitude: res.data.data.longitude,
					description: res.data.data.description,
					images: res.data.data.images.filter(Boolean),
					mainImage: [res.data.data.main_image].filter(Boolean),
				},
			}));

			setStatus(REQUEST_STATUS[res.data.data.status]);
		};

		if (id && countries.length) {
			loadHotelRequestData();
		}
	}, [id, countries]);

	useEffect(() => {
		const loadCountries = async () => {
			const res = await fetchPackageCountries();
			setCountries(res.data.data);
		};

		loadCountries();
	}, []);

	return (
		<div className="static-requests">
			<h1>Details</h1>
			<hr />

			<form
				className="hotel-edit-form"
				onSubmit={handleSubmit}
				id="hotel-request"
			>
				<div className="row">
					<div className="col-6 p-0">
						<TextField
							label="Hotel Name (EN)"
							placeholder="Enter name in English"
							name="hotelNameEn"
							id="hotelNameEn"
							value={formState.values.hotelNameEn}
							onChange={(e) =>
								handleOnChange({
									key: "hotelNameEn",
									value: e.target.value,
									validation: { required: true },
								})
							}
							color={formState.errors?.hotelNameEn?.required ? "danger" : ""}
							errors={formState.errors?.hotelNameEn}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-6 p-0">
						<TextField
							label="Hotel Name (AR)"
							placeholder="Enter name in Arabic"
							name="hotelNameAr"
							id="hotelNameAr"
							value={formState.values.hotelNameAr}
							onChange={(e) =>
								handleOnChange({
									key: "hotelNameAr",
									value: e.target.value,
									validation: { required: true },
								})
							}
							color={formState.errors?.hotelNameAr?.required ? "danger" : ""}
							errors={formState.errors?.hotelNameAr}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-12"></div>

					<div className="col-4 p-0">
						<SelectField
							label="Country"
							placeholder="Select Option"
							name="country"
							id="country"
							value={formState.values.country}
							options={countries.map((country) => ({
								...country,
								value: country.id,
								label: country.names?.en,
							}))}
							onChange={(country) => {
								handleOnChange({
									key: "country",
									value: country,
									validation: { required: true },
								});
								handleOnChange({
									key: "city",
									value: "",
									validation: { required: false },
								});
								loadCities(country?.id);
							}}
							color={formState.errors?.country?.required ? "danger" : ""}
							errors={formState.errors?.country}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-4 p-0">
						<SelectField
							label="City"
							placeholder="Select Option"
							name="city"
							id="city"
							value={formState.values.city}
							options={cities.map((city) => ({
								...city,
								value: city.id,
								label: city.names?.en,
							}))}
							onChange={(city) =>
								handleOnChange({
									key: "city",
									value: city,
									validation: { required: true },
								})
							}
							color={formState.errors?.city?.required ? "danger" : ""}
							errors={formState.errors?.city}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-4 p-0">
						<SelectField
							label="Rating"
							placeholder="Select Option"
							name="rating"
							id="rating"
							options={RATINGS.map((rating) => ({
								value: rating,
								label: rating,
							}))}
							value={formState.values.rating}
							onChange={(e) =>
								handleOnChange({
									key: "rating",
									value: e,
									validation: { required: true },
								})
							}
							color={formState.errors?.rating?.required ? "danger" : ""}
							errors={formState.errors?.rating}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-4 px-2">
						<PhoneInput
							label={"Phone Number (1)"}
							selectedCountry={formState.values.phoneNumberCountry}
							phoneNumber={formState.values.phoneNumber}
							countries={countries}
							onCountryChange={(country) =>
								country &&
								handleOnChange({ key: "phoneNumberCountry", value: country })
							}
							onPhoneChange={(value) =>
								handleOnChange({
									key: "phoneNumber",
									value,
									validation: { required: true },
								})
							}
							showError={formState.errors?.phoneNumber?.required}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-4 px-2">
						<PhoneInput
							label={"Phone Number (2)"}
							optional={"- Optional"}
							selectedCountry={formState.values.phoneNumber2Country}
							phoneNumber={formState.values.phoneNumber2}
							countries={countries}
							onCountryChange={(country) =>
								country &&
								handleOnChange({ key: "phoneNumber2Country", value: country })
							}
							onPhoneChange={(value) =>
								handleOnChange({
									key: "phoneNumber2",
									value,
									validation: { required: false },
								})
							}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-4 px-2">
						<PhoneInput
							label={"WhatsApp Number"}
							optional={"- Optional"}
							countries={countries}
							selectedCountry={formState.values.whatsappNumberCountry}
							phoneNumber={formState.values.whatsappNumber}
							onCountryChange={(country) =>
								country &&
								handleOnChange({ key: "whatsappNumberCountry", value: country })
							}
							onPhoneChange={(value) =>
								handleOnChange({
									key: "whatsappNumber",
									value,
									validation: { required: false },
								})
							}
							disabled={status !== "pending"}
						/>
					</div>

					{/* <div className="col-12 p-0">
						<AmenitiesField
							options={[
								{ value: "wifi", label: "Wi-Fi" },
								{ value: "pool", label: "Swimming Pool" },
								{ value: "parking", label: "Parking" },
								{ value: "gym", label: "Gym" },
							]}
							onChange={(selected) => {
								handleOnChange({
									key: "amenities",
									value: selected,
									validation: { required: true },
								});
							}}
							value={formState.values.amenities}
							showError={formState.errors?.amenities?.required}
						/>
					</div> */}

					<div className="col-12 p-0">
						<div className="d-flex">
							<div className="me-2 w-100">
								<TextField
									label="Amenities"
									placeholder="Enter amenities"
									name="amenities"
									id="amenities"
									onChange={(e) => {
										handleOnChange({
											key: "amenity",
											value: e.target.value,
											validation: { required: true },
										});
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											e.preventDefault();
											e.target.value &&
												handleOnChange({
													key: "amenities",
													value: formState.values.amenities.concat(
														formState.values.amenity
													),
													validation: { required: true },
												});
										}
									}}
									color={formState.errors?.amenities?.required ? "danger" : ""}
									errors={formState.errors?.amenities}
									disabled={status !== "pending"}
								/>
							</div>

							<button
								className="btn btn-primary mt-2"
								type="button"
								style={{
									height: "40px",
									transform: "translateY(33px)",
									display: "block",
								}}
								onClick={() => {
									if (!formState.values.amenity) return;
									status === "pending" &&
										handleOnChange({
											key: "amenities",
											value: formState.values.amenities.concat(
												formState.values.amenity
											),
											validation: { required: true },
										});
								}}
								disabled={status !== "pending" || !formState.values.amenity}
							>
								Add
							</button>
						</div>

						<ul className="slelected-amenities-list px-2">
							{formState.values.amenities.map((option) => (
								<li key={option.value} className="slelected-amenities-item">
									<button
										disabled={status !== "pending"}
										onClick={() =>
											status === "pending" &&
											handleOnChange({
												key: "amenities",
												value: formState.values.amenities.filter(
													(item) => item !== option
												),
												validation: { required: true },
											})
										}
									>
										<span>{option}</span>
										<i className="fas fa-times"></i>
									</button>
								</li>
							))}
						</ul>
					</div>

					<div className="col-4 p-0">
						<TextField
							label="Address"
							placeholder="Enter address details"
							name="address"
							id="address"
							value={formState.values.address}
							onChange={(e) => {
								handleOnChange({
									key: "address",
									value: e.target.value,
									validation: { required: true },
								});
							}}
							color={formState.errors?.address?.required ? "danger" : ""}
							errors={formState.errors?.address}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-4 p-0">
						<TextField
							label="Latitude"
							placeholder="Enter Lat Code e.g(0.000000)"
							name="latitude"
							id="latitude"
							value={formState.values.latitude}
							onChange={(e) => {
								handleOnChange({
									key: "latitude",
									value: e.target.value,
									validation: { required: true },
								});
							}}
							color={formState.errors?.latitude?.required ? "danger" : ""}
							errors={formState.errors?.latitude}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-4 p-0">
						<TextField
							label="Longitude"
							placeholder="Enter Long Code e.g(0.000000)"
							name="longitude"
							id="longitude"
							value={formState.values.longitude}
							onChange={(e) => {
								handleOnChange({
									key: "longitude",
									value: e.target.value,
									validation: { required: true },
								});
							}}
							color={formState.errors?.longitude?.required ? "danger" : ""}
							errors={formState.errors?.longitude}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-12 p-0">
						<TextAreaField
							label="Description"
							placeholder="Enter description"
							name="description"
							id="description"
							rows="4"
							value={formState.values.description}
							onChange={(e) => {
								handleOnChange({
									key: "description",
									value: e.target.value,
									validation: { required: true },
								});
							}}
							color={formState.errors?.description?.required ? "danger" : ""}
							errors={formState.errors?.description}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-6 p-0">
						<ImageUpload
							setImages={(selectedImages) => {
								handleOnChange({
									key: "mainImage",
									value: selectedImages,
									validation: { required: true },
								});
							}}
							images={formState?.values?.mainImage}
							label="Main Image"
							showError={formState.errors?.mainImage?.required}
							disabled={status !== "pending"}
						/>
					</div>

					<div className="col-6 p-0">
						<ImageUpload
							isMulti={true}
							setImages={(selectedImages) => {
								handleOnChange({
									key: "images",
									value: selectedImages,
									validation: { required: true },
								});
							}}
							images={formState?.values?.images}
							label="Images"
							showError={formState.errors?.images?.required}
							disabled={status !== "pending"}
						/>
					</div>
				</div>
			</form>

			<div className="bottom-actions d-flex justify-content-between mt-4 px-2">
				<Link to="/static-requests/hotels">
					<button className="btn btn-primary">Close</button>
				</Link>

				{status === "pending" && (
					<div className="d-flex">
						<button
							className="btn btn-danger me-2"
							onClick={() => {
								setIsRejectModalOpen(true);
							}}
						>
							Reject
						</button>
						<button
							className="btn btn-primary"
							form="hotel-request"
							type="submit"
						>
							Save & Add Approve
						</button>
					</div>
				)}
			</div>

			<RejectModal
				isOpen={isRejectModalOpen}
				setIsOpen={setIsRejectModalOpen}
				rejectMessage={rejectMessage}
				setRejectMessage={setRejectMessage}
				handleReject={handleReject}
			/>
		</div>
	);
}
