import { components } from "react-select";

const IMAGE_STORAGE_URL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

export const CountryOption = (props) => {
	const { data } = props;

	return (
		<components.Option {...props}>
			<div className="country-option">
				{data.flag ? (
					<img src={`${IMAGE_STORAGE_URL}/${data.flag}`} alt={data.name} />
				) : (
					<div className="country-flag-fallback"></div>
				)}
				<span>{data.name}</span>
				<span className="country-code">+{data?.id}</span>
			</div>
		</components.Option>
	);
};
