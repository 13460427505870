import DatePickerField from "components/shared/DatePickerField";
import Pagination from "components/shared/Pagination";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useMemo } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { fetchCountries } from "services/lookups";
import { listFlights } from "services/staticRequests";
import { Edit, Eye } from "../../package/icons";
import { REQUEST_STATUS } from "../constants";
import { DetailsOverlay } from "../shared/DetailsOverlay";
import useFilters from "../shared/hooks/useFilters";
import useListData from "../shared/hooks/useListData";
import useLookups from "../shared/hooks/useLookups";
import { formatFilter } from "../shared/utils";

const INITIAL_FILTERS = {
	search: "",
	country: "",
	date: "",
	status: "all",
	page: 1,
};

export default function Flights() {
	const { filters, setFilters, handleFilterChange, resetFilters } =
		useFilters(INITIAL_FILTERS);

	const formattedFilters = useMemo(() => formatFilter(filters), [filters]);

	const { list: flightsList, meta } = useListData(
		listFlights,
		formattedFilters
	);

	const { lookups } = useLookups(fetchCountries);

	const goToPage = (page) => {
		setFilters((prev) => ({ ...prev, page }));
	};

	const handleStatusFilter = (status) => {
		setFilters((prev) => ({ ...prev, status }));
	};

	return (
		<div className="static-requests">
			<div className="static-requests-hotels">
				<h1>Flights</h1>
				<hr />

				<div className="hotel-filter">
					<div className="row align-items-end w-100">
						<div className="col-6 col-md-2 p-0">
							<TextField
								label="Search"
								placeholder="Type Product Name"
								name="search"
								id="search"
								value={filters.search}
								onChange={(e) => handleFilterChange("search", e.target.value)}
							/>
						</div>
						<div className="col-6 col-md-2 p-0">
							<SelectField
								label="Country"
								placeholder="Select Option"
								name="country"
								id="country"
								options={lookups.countries.map((country) => ({
									...country,
									value: country.id,
									label: country.names?.en,
								}))}
								value={filters.country}
								onChange={(country) => handleFilterChange("country", country)}
							/>
						</div>
						<div className="col-6 col-md-2 mb-2">
							<DatePickerField
								label="Date"
								placeholder="Select Date"
								name="date"
								id="date"
								date={filters.date}
								onDateChange={(date) => handleFilterChange("date", date)}
							/>
						</div>
						<div
							className="col-6 col-md-2 px-2"
							style={{ marginBottom: "10px" }}
						>
							<button className="btn btn-primary" onClick={resetFilters}>
								Clear
							</button>
						</div>
					</div>
				</div>

				<hr />

				<ul className="filter-tabs">
					{Object.keys(REQUEST_STATUS).map((status) => (
						<li key={status}>
							<button
								className={`rounded-filter-tab ${
									filters.status === status ? "active" : ""
								}`}
								onClick={() => handleStatusFilter(status)}
							>
								{REQUEST_STATUS[status]}
							</button>
						</li>
					))}
				</ul>

				<div className="table-wrapper mt-4">
					<table className="request-table">
						<thead>
							<tr>
								<th scope="col">ID</th>
								<th scope="col">Requested By</th>
								<th scope="col">Airline Name</th>
								<th scope="col">Country</th>
								<th scope="col">Request Date</th>
								<th scope="col">Status</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>
							{flightsList.map((flight) => {
								let status = flight.status;

								if (status === "new") {
									status = "pending";
								}

								return (
									<tr key={flight.id}>
										<td className="request-id">#{flight.id}</td>
										<td>
											<div>
												<span>{flight?.company?.name?.en || "--"}</span>
												<DetailsOverlay
													phoneNumber={`${flight?.company?.phone?.phone_code} ${flight?.company?.phone?.phone}`}
													country={flight?.company?.country.name || "--"}
												/>
											</div>
										</td>
										<td>{flight?.name || "--"}</td>
										<td>
											<div>{flight?.country?.name || "--"}</div>
										</td>
										<td>{moment(flight.created_at).format("DD MMM YYYY")}</td>
										<td>
											<span className={`request-status ${status}-request`}>
												{status || "--"}
											</span>
										</td>
										<td>
											{flight.status === "new" ? (
												<Link to={`/static-requests/flights/edit/${flight.id}`}>
													<Edit />
												</Link>
											) : (
												<Link to={`/static-requests/flights/view/${flight.id}`}>
													<Eye />
												</Link>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>

				<div className="mt-3">
					<Pagination goTo={goToPage} meta={meta} />
				</div>
			</div>
		</div>
	);
}
