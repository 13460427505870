import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Eye } from "../../package/icons";

export const DetailsOverlay = ({ phoneNumber, country }) => {
	return (
		<OverlayTrigger
			placement="bottom-start"
			flip={true}
			overlay={
				<Tooltip id={`request-details-tooltip`}>
					<p className="tooltip-title">Contact Info</p>
					<hr />
					<div className="tooltip-content">
						<p>Phone Number</p>
						<p>{phoneNumber}</p>
					</div>
					<div className="tooltip-content">
						<p>Country</p>
						<p>{country}</p>
					</div>
				</Tooltip>
			}
		>
			<div className="view-details">
				<Eye />
				<span>View Details</span>
				<div className="details"></div>
			</div>
		</OverlayTrigger>
	);
};
