import { useState } from "react";

const useFilters = (initialFilters) => {
	const [filters, setFilters] = useState(initialFilters);

	const handleFilterChange = (key, value) => {
		setFilters((prev) => ({ ...prev, [key]: value }));
	};

	const resetFilters = () => {
		setFilters(initialFilters);
	};

	return { filters, setFilters, handleFilterChange, resetFilters };
};

export default useFilters;
