import Select, { components } from "react-select";
import { CountryOption } from "./CountryOption";

const IMAGE_STORAGE_URL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

const SingleValue = (props) => {
	const { data } = props;

	return (
		<components.SingleValue {...props}>
			<div className="select-value">
				{data.flag ? (
					<img src={`${IMAGE_STORAGE_URL}/${data.flag}`} alt={data.name} />
				) : (
					<span>+{data?.id}</span>
				)}
			</div>
		</components.SingleValue>
	);
};

export const PhoneInput = ({
	selectedCountry,
	phoneNumber,
	onCountryChange,
	onPhoneChange,
	countries,
	optional,
	label,
	showError,
	disabled,
}) => {
	return (
		<div className="phone-field-wrapper">
			{label ? (
				<label className="phone-field-label">
					{label} {""}
					{optional ? <span className="optional-label">{optional}</span> : null}
				</label>
			) : null}

			<div className="phone-field">
				<Select
					value={selectedCountry}
					options={countries}
					onChange={onCountryChange}
					components={{
						Option: CountryOption,
						SingleValue,
						IndicatorSeparator: () => null,
					}}
					styles={{
						control: (provided) => ({
							...provided,
							height: "100%",
							width: "5rem",
							maxWidth: "5rem",
							borderRadius: "4px",
						}),
						menu: (provided) => ({
							...provided,
							minWidth: "max-content",
						}),
					}}
					className="country-select"
					classNames={{
						control: () => "select-control",
						valueContainer: () => "select-value-container",
						option: (state) =>
							`select-option ${state.isFocused ? "focused" : ""}`,
						menu: () => "select-menu",
					}}
					isSearchable={true}
					getOptionValue={(opt) => opt.name}
					isDisabled={disabled}
				/>
				<div
					className={`phone-input-wrapper ${showError ? "border-danger" : ""}`}
				>
					<div>+{selectedCountry?.id}</div>
					<input
						type="number"
						placeholder="000 0000 000"
						value={phoneNumber}
						onChange={(e) => onPhoneChange(e.target.value)}
						disabled={disabled}
					/>
				</div>
			</div>
			{showError && (
				<small className="text-danger mt-1">
					Please enter a valid phone number.
				</small>
			)}
		</div>
	);
};
