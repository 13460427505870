import generateUniqueID from "helpers/generateUniqueID";
import { useRef } from "react";
import { flushSync } from "react-dom";
import { ArrowUpIcon, GalleryIcon } from "./icons";

const getImageUrl = (image) => {
	if (image instanceof File) {
		return URL.createObjectURL(image);
	}

	return image;
};

export default function ImageUpload({
	isMulti,
	images = [],
	setImages,
	label,
	showError,
	errorMessage = "Please select at least one image.",
	disabled,
}) {
	const id = generateUniqueID();
	const isRemoving = useRef(false);

	const handleDeleteImage = (imageToRemove) => {
		flushSync(() => {
			const updatedImages = images.filter((image) => image !== imageToRemove);
			if (updatedImages.length === 0) {
				isRemoving.current = true;
			}
			setImages(updatedImages);
		});
	};

	const handleInputClick = (e) => {
		if (isRemoving.current) {
			e.preventDefault();
			isRemoving.current = false;
		}
	};

	return (
		<div className="image-upload">
			<span
				className={`image-upload-label mb-2 d-block ${
					showError ? "text-danger" : ""
				}`}
			>
				{label}
			</span>
			<label
				htmlFor={id}
				className={`mb-0 ${showError ? "text-danger" : ""}`}
				onDrop={(e) => {
					if (disabled) return;
					e.preventDefault();
					const selectedFiles = e.dataTransfer.files;
					if (selectedFiles.length === 0) return;

					if (isMulti) {
						setImages([...images, ...selectedFiles]);
					} else {
						setImages([selectedFiles[0]]);
					}
				}}
			>
				{images.length > 0 ? (
					<div className="image-card-list">
						{images.map((image, index) => (
							<div className="image-card" key={index + image}>
								<img src={getImageUrl(image)} alt="uploaded" />
								{!disabled && (
									<div className="image-card-overlay">
										<button
											disabled={disabled}
											type="button"
											className="btn btn-danger"
											onClick={() => disabled || handleDeleteImage(image)}
										>
											Delete
										</button>
									</div>
								)}
							</div>
						))}
					</div>
				) : (
					<>
						<div className="icon-wrapper">
							<GalleryIcon />
							<span className="arrow-up-icon">
								<ArrowUpIcon />
							</span>
						</div>
						<p>Upload Photo</p>
					</>
				)}
			</label>
			<input
				type="file"
				id={id}
				multiple={isMulti}
				accept="image/jpeg, image/png, image/jpg"
				disabled={images.length > 0 || disabled}
				onClick={disabled || handleInputClick}
				onChange={async (e) => {
					if (disabled) return;
					const selectedFiles = e.target.files;
					if (selectedFiles.length === 0) return;

					if (isMulti) {
						setImages([...images, ...selectedFiles]);
					} else {
						setImages([selectedFiles[0]]);
					}
				}}
			/>
			{showError && images.length === 0 && (
				<p className="text-danger mt-1">{errorMessage}</p>
			)}
		</div>
	);
}
