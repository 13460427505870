import axios from "axios";
import { cleanEmpty } from "services/general";

const staticRequestsUrl = process.env.REACT_APP_API_URL + "/api";

export const listHotels = async (params, queryParams) => {
	try {
		const query = new URLSearchParams(queryParams).toString();

		const res = await axios.get(
			`${staticRequestsUrl}/v1/lookup-suggestions/hotels${
				query ? `?${query}` : ""
			}`,
			{
				params: cleanEmpty(params),
			}
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const listFlights = async (params, queryParams) => {
	try {
		const query = new URLSearchParams(queryParams).toString();

		const res = await axios.get(
			`${staticRequestsUrl}/v1/lookup-suggestions/flights${
				query ? `?${query}` : ""
			}`,
			{
				params: cleanEmpty(params),
			}
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const listTransporters = (params, queryParams) => {
	try {
		const query = new URLSearchParams(queryParams).toString();

		return axios.get(
			`${staticRequestsUrl}/v1/lookup-suggestions/transporters${
				query ? `?${query}` : ""
			}`,
			{
				params: cleanEmpty(params),
			}
		);
	} catch (err) {
		return err.response;
	}
};

export const getHotel = async (id) => {
	try {
		const res = await axios.get(
			`${staticRequestsUrl}/v1/lookup-suggestions/hotels/${id}`
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const getFlight = async (id) => {
	try {
		const res = await axios.get(
			`${staticRequestsUrl}/v1/lookup-suggestions/flights/${id}`
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const getTransporter = async (id) => {
	try {
		const res = await axios.get(
			`${staticRequestsUrl}/v1/lookup-suggestions/transporters/${id}`
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const approveHotelRequest = async (id, data) => {
	try {
		const res = await axios.post(
			`${staticRequestsUrl}/v1/lookup-suggestions/hotels/${id}/approve`,
			cleanEmpty(data)
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const rejectHotelRequest = async (id, data) => {
	try {
		const res = await axios.post(
			`${staticRequestsUrl}/v1/lookup-suggestions/hotels/${id}/reject`,
			cleanEmpty(data)
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const approveFlightRequest = async (id, data) => {
	try {
		const res = await axios.post(
			`${staticRequestsUrl}/v1/lookup-suggestions/flights/${id}/approve`,
			cleanEmpty(data)
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const rejectFlightRequest = async (id, data) => {
	try {
		const res = await axios.post(
			`${staticRequestsUrl}/v1/lookup-suggestions/flights/${id}/reject`,
			cleanEmpty(data)
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const approveTransporterRequest = async (id, data) => {
	try {
		const res = await axios.post(
			`${staticRequestsUrl}/v1/lookup-suggestions/transporters/${id}/approve`,
			cleanEmpty(data)
		);
		return res;
	} catch (err) {
		return err.response;
	}
};

export const rejectTransportersRequest = async (id, data) => {
	try {
		const res = await axios.post(
			`${staticRequestsUrl}/v1/lookup-suggestions/transporters/${id}/reject`,
			cleanEmpty(data)
		);
		return res;
	} catch (err) {
		return err.response;
	}
};
