import moment from "moment";
import { uploadFile } from "services/auth";

export const formatFilter = (filters) => {
	return {
		search: filters.search,
		country_id: filters.country?.id,
		city_id: filters.city?.id,
		date: filters.date ? moment(filters.date).format("YYYY-MM-DD") : "",
		page: filters.page,
		status: filters.status === "all" ? "" : filters.status,
	};
};

export const uploadImageFromFileObject = async (file) => {
	const FETCH_IMAGE_URL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
	const formData = new FormData();
	formData.append("file", file);

	const res = await uploadFile(formData);

	if (res.status === 200) {
		return `${FETCH_IMAGE_URL}/${res.data.uuid}`;
	}
	throw new Error(`Upload failed with status: ${res.status}`);
};

export const uploadImagesFromFormState = async (images) => {
	const imagesURLs = await Promise.all(
		images.map(async (image) => {
			try {
				if (image instanceof File) {
					return await uploadImageFromFileObject(image);
				} else if (typeof image === "string") {
					return image;
				} else {
					throw new Error("Invalid image type");
				}
			} catch (error) {
				return null;
			}
		})
	);

	// Filter out null or undefined values
	return imagesURLs.filter(Boolean);
};
